@import 'typography.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  @apply bg-almostBlack text-white text-sm md:text-md font-sans font-medium;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
select,
.alt-form textarea,
.form-element {
  -webkit-appearance: none;
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  @apply w-full p-3 placeholder-white border-0 border-b-2 border-r-0 border-white appearance-none bg-almostBlack focus:border-yellow focus:outline-none focus:ring-0 box-border;
}

select {
  -webkit-appearance: none;
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  @apply form-select w-full py-3 placeholder-white border-0 border-b-2 border-r-0 border-white appearance-none bg-almostBlack focus:border-yellow focus:outline-none focus:ring-0  box-border;
}

i,
em {
  @apply italic;
}

strong,
em {
  @apply font-bold;
}

/* Override to fix spacing after next/image in footer */
footer span[style^='box-sizing:border-box;display:inline-block'] {
  display: block !important;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@layer components {
  .__shift-lab-case-study {
    @apply col-span-full lg:col-span-6 lg:col-start-8;
  }

  .__shift-lab-case-study:nth-child(odd) {
    @apply col-span-full lg:col-span-6;
  }

  .__shift-lab-button-link {
    @apply md:pt-2 pt-3 pb-3 px-6 inline-block;
  }

  .__shift-lab-button-secondary-link {
    @apply md:pt-2 pt-3 pb-3 px-6 inline-block;
  }

  .__shift-lab-spacing-responsive-xs {
    @apply pb-[6px] lg:pb-[10px] xl:pb-[15px];
  }

  .__shift-lab-spacing-responsive-sm {
    @apply pb-[12px] lg:pb-[20px] xl:pb-[30px];
  }

  .__shift-lab-spacing-responsive-md {
    @apply pb-[24px] lg:pb-[40px] xl:pb-[60px];
  }

  .__shift-lab-spacing-responsive-lg {
    @apply pb-[36px] lg:pb-[60px] xl:pb-[90px];
  }

  .__shift-lab-spacing-responsive-xl {
    @apply pb-[48px] lg:pb-[80px] xl:pb-[120px];
  }

  .__shift-lab-spacing-responsive-2xl {
    @apply pb-[96px] lg:pb-[160px] xl:pb-[240px];
  }

  .__shift-lab-spacing-responsive-3xl {
    @apply pb-[190px] lg:pb-[320px] xl:pb-[480px];
  }

  .__shift-lab-spacing-static-xs {
    @apply pb-[15px];
  }

  .__shift-lab-spacing-static-sm {
    @apply pb-[30px];
  }

  .__shift-lab-spacing-static-md {
    @apply pb-[60px];
  }

  .__shift-lab-spacing-static-lg {
    @apply pb-[90px];
  }

  .__shift-lab-spacing-static-xl {
    @apply pb-[120px];
  }

  .__shift-lab-spacing-static-2xl {
    @apply pb-[240px];
  }

  .__shift-lab-spacing-static-3xl {
    @apply pb-[480px];
  }

  .__shift-lab-svg-animate-blink.__shift-lab-icon-cursor {
    animation: 1.1s normal forwards ease-in-out;
  }

  .__shift-lab-svg-animate-blink {
    animation: blink 0.75s infinite ease-in-out;
    animation-delay: 0.65s;
  }

  .__shift-lab-rich-text-block h1,
  .__shift-lab-rich-text-block h2,
  .__shift-lab-rich-text-block h3,
  .__shift-lab-rich-text-block h4,
  .__shift-lab-rich-text-block h5,
  .__shift-lab-rich-text-block h6 {
    @apply __shift-lab-spacing-responsive-xs;
  }

  .__shift-lab-typography-text-body .__shift-lab-rich-text-block p,
  .__shift-lab-typography-text-body .__shift-lab-rich-text-block blockquote,
  .__shift-lab-typography-text-body .__shift-lab-rich-text-block ul,
  .__shift-lab-typography-text-body .__shift-lab-rich-text-block ol {
    @apply __shift-lab-spacing-responsive-sm leading-7;
  }

  .__shift-lab-typography-text-base .__shift-lab-rich-text-block p,
  .__shift-lab-typography-text-base .__shift-lab-rich-text-block ul,
  .__shift-lab-typography-text-base .__shift-lab-rich-text-block ol {
    @apply __shift-lab-spacing-responsive-xs;
  }

  .__shift-lab-rich-text-block h1,
  .__shift-lab-rich-text-block h2 {
    @apply __shift-lab-typography-display-h2;
  }

  .__shift-lab-rich-text-block h3,
  .__shift-lab-rich-text-block h4,
  .__shift-lab-rich-text-block h5,
  .__shift-lab-rich-text-block h6 {
    @apply __shift-lab-typography-display-h4 font-bold relative;
  }

  .__shift-lab-rich-text-block h4 {
    @apply mb-3;
  }

  .__shift-lab-rich-text-block h4:after {
    content: '';
    @apply block w-[64px] h-[6px] bg-yellow absolute left-0 bottom-0;
  }

  .__shift-lab-rich-text-block h4,
  .__shift-lab-rich-text-block h5,
  .__shift-lab-rich-text-block h6 {
    @apply __shift-lab-typography-display-h3;
  }

  .__shift-lab-rich-text-block p * {
    display: inline;
  }

  .__shift-lab-rich-text-block a,
  .__shift-lab-rich-text-block a:active,
  .__shift-lab-rich-text-block a:link,
  .__shift-lab-rich-text-block a:visited {
    @apply __shift-lab-typography-text-inline-link;
  }

  .__shift-lab-rich-text-block ul,
  .__shift-lab-rich-text-block ol {
    @apply mb-0;
  }

  .__shift-lab-rich-text-block ul > li {
    @apply before:content-['+'] before:flex before:pr-6 flex;
  }

  .__shift-lab-rich-text-block ol > li {
    @apply ml-6 pl-4;
  }
  .__shift-lab-rich-text-block ol {
    @apply list-decimal;
  }

  .__shift-lab-rich-text-block li p {
    @apply !mb-0 !pb-2;
  }

  .__shift-lab-rich-text-block code {
    @apply block border-l-4 border-white font-bold p-4;
  }

  .__shift-lab-rich-text-block blockquote {
    @apply italic pt-12 pl-12 mt-2 mb-12 bg-yellow;
  }

  .__shift-lab-rich-text-block blockquote p {
    @apply pl-8 relative w-[80%];
  }

  .__shift-lab-rich-text-block blockquote p:before {
    content: '“';
    @apply text-3xl absolute -top-6 -left-8;
  }

  .__shift-lab-embedded-form {
    @apply bg-almostBlack;
  }

  .__shift-lab-embedded-form div > label {
    @apply hidden;
  }

  .__shift-lab-embedded-form > div form ul {
    @apply text-white text-xs p-2 pb-0 flex;
  }

  .__shift-lab-embedded-form > div form > div {
    @apply mb-4;
  }

  .__shift-lab-embedded-form > div form > div:last-of-type {
    @apply mb-0;
  }

  .__shift-lab-embedded-form [type='text'],
  .__shift-lab-embedded-form [type='email'],
  .__shift-lab-embedded-form [type='url'],
  .__shift-lab-embedded-form [type='password'],
  .__shift-lab-embedded-form [type='number'],
  .__shift-lab-embedded-form [type='date'],
  .__shift-lab-embedded-form [type='datetime-local'],
  .__shift-lab-embedded-form [type='month'],
  .__shift-lab-embedded-form [type='search'],
  .__shift-lab-embedded-form [type='tel'],
  .__shift-lab-embedded-form [type='time'],
  .__shift-lab-embedded-form [type='week'],
  .__shift-lab-embedded-form [multiple],
  .__shift-lab-embedded-form select {
    @apply pt-4 p-2 pb-1.5 placeholder-white border-white bg-almostBlack focus:border-yellow text-almostBlack;
  }

  .__shift-lab-embedded-form textarea {
    @apply h-[150px] w-full p-2 mt-8 mb-4 placeholder-white border-2 border-white text-white appearance-none bg-almostBlack focus:border-yellow focus:outline-none focus:ring-0 box-border;
  }

  .__shift-lab-embedded-form select {
    -webkit-appearance: none;
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    @apply placeholder-white  border-white focus:border-yellow text-almostBlack bg-almostBlack;
  }

  .__shift-lab-embedded-form input[type='submit'] {
    @apply __shift-lab-button-link mt-4 font-bold transition-all border-yellow text-yellow bg-almostBlack relative inline-block border-2 cursor-pointer;
  }

  .__shift-lab-embedded-form .hs-form-field {
    @apply m-0 mb-3 md:inline-block md:w-[50%] pr-6;
  }

  .__shift-lab-embedded-form .hs-form-field.hs-fieldtype-textarea,
  .__shift-lab-embedded-form .hs-form-field.hs-email {
    @apply block w-[100%];
  }

  .__shift-lab-embedded-form .hs-error-msg {
    @apply text-xs;
  }

  .__shift-lab-embedded-form .hs-error-msgs {
    @apply absolute text-yellow p-0 pt-1;
  }
}
