@font-face {
  font-family: 'Aeonik';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/Aeonik-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 500;
  font-style: italic;
  src: url('/fonts/Aeonik-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Aeonik-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/Aeonik-BoldItalic.ttf') format('truetype');
}

@layer components {
  .__shift-lab-typography-display-super {
    @apply text-3xl lg:text-super;
  }

  .__shift-lab-typography-display-h1 {
    @apply text-xl lg:text-3xl;
  }

  .__shift-lab-typography-display-h2 {
    @apply text-lg lg:text-xl;
  }

  .__shift-lab-typography-display-h3 {
    @apply text-sm lg:text-md;
  }

  .__shift-lab-typography-display-h4 {
    @apply text-md md:text-lg;
  }

  .__shift-lab-typography-text-body {
    @apply text-sm md:text-md lg:text-lg;
  }

  .__shift-lab-typography-text-base {
    @apply text-xs md:text-sm;
  }

  .__shift-lab-typography-text-inline-link {
    @apply font-bold underline hover:text-yellow;
  }

  .__shift-lab-typography-text-inline-link-yellow {
    @apply font-bold underline text-yellow hover:text-white;
  }
}
